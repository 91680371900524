import { Box, Card, Typography } from '@mui/material';
import { useNavigate, useParams, } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { SingleGridElementProps } from 'src/@types/list';
import { ShopBundleFilters, DEFAULT_SHOP_BUNDLE_FILTERS, ShopBundleSearchResult } from 'src/@types/webshop';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { ToolbarSearchFilters } from 'src/@types/commons';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { CurrencyTypes } from 'src/@types/vehicle';
import { OrderAmount } from 'src/@types/orders';
import { webshopOperations } from 'src/redux/webshop';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

export default function ProductsListDetail() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { id } = useParams();

    const { isProductLoading, productList, productTotalCount } = useSelector((state: RootState) => state.webshop);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopBundleFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_BUNDLE_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: ShopBundleFilters & { sequenceToken: any }, check: boolean }) => {
        if (id) dispatch(webshopOperations.searchShopProducts({ ...filters, id: id }));
    }, [id]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'name', label: translate('spareParts.list.tableHeaders.partDesc') },
            { key: 'externalId', label: translate('spareParts.list.tableHeaders.partNumber') },
            { key: 'familySupplier', label: translate('webshop.detail.products.familySupplier') },
        ], [translate]);

    //---- HANDLE TABLE START ----//
    const [actualRow, setActualRow] = useState<GridCellParams<ShopBundleSearchResult> | null>(null);

    const handleCellClick = (params: GridCellParams<ShopBundleSearchResult>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.webshop.detail(params.row.items[0].productId));
        }
    };

    const getPrice = useCallback((prices: OrderAmount) => {
        if (!prices) return 0;
        if (prices.withoutVat) return prices.withoutVat;
        if (prices.withVat) return prices.withVat;

        return '—';
    }, []);

    const COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1.75 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', alignItems: "center" }}>

                    {obj.row.items[0].media[0] ?
                        <img
                            alt={""}
                            src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography variant='subtitle2' sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            <b>{"[" + obj.row.items[0].family.supplier.code + "] - "}</b>{obj.row.items[0].family.supplier.description}
                        </Typography>
                    </Box>
                </Box>
        },
        {
            field: 'externalId',
            headerName: translate('spareParts.list.tableHeaders.partNr'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.grossAmount)}
                        currency={obj.row.price.grossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.grossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        /*         {
                    field: 'unitDiscountAmount',
                    headerName: translate('webshop.detail.products.discountAmount'),
                    flex: isDesktop ? 1 : undefined,
                    minWidth: !isDesktop ? 250 : undefined,
                    renderCell: (obj) => {
                        return (
                            <CurrencyAdapter
                                value={getPrice(obj.row.price.unitDiscountAmount)}
                                currency={obj.row.price.unitDiscountAmount.currentCurrency}
                                noVatAsterisk={!!obj.row.price.unitDiscountAmount.withoutVat}
                                fontSize={'0.875rem'}
                            />
                        );
                    }
                }, */
        {
            field: 'unitDiscountAmount',
            headerName: translate('webshop.detail.products.discountAmount'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            valueGetter: (_, row) => "—"
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.unitDiscountedAmount)}
                        currency={obj.row.price.unitDiscountedAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.unitDiscountedAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'availability.stockQuantity',
            headerName: translate('spareParts.list.tableHeaders.stockQuantity'),
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            valueGetter: (_, row) => row.availability.stockQuantity ?? "—"
        }
    ], [translate, isDesktop, getPrice]);
    // ----HANDLE TABLE END---- //

    return (
        <>
            <InfiniteScrollGenericList
                list={productList.map((bundle) => ({ ...bundle, id: bundle.bundleId, ...bundle.items[0] }))}
                isLoading={isProductLoading}
                totalCount={productTotalCount}
                defaultFilters={DEFAULT_SHOP_BUNDLE_FILTERS}
                toolbarFiltersList={toolbarFiltersList}
                datagridColumns={COLUMNS}
                updateCheckField={updateCheckField}
                context={"WebshopProduct"}
                setActualRow={setActualRow}
                handleCellClick={handleCellClick}
                customSearchFunc={customSearch}
                resetList={() => { }}
                multipleView={{
                    renderGridElement: SingleGridElement
                }}
            />

            <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
                {`${translate('orders.messages.vatAdvice')}`}
            </Typography>
        </>
    );
}

//-----------------------------------------------------------------------------

function SingleGridElement({ key, item }: SingleGridElementProps<ShopBundleSearchResult>) {

    return (
        <Card
            key={key}
            sx={{
                p: 1,
                transition: "0.5s",
                cursor: 'pointer',
                ':hover': {
                    bgcolor: (theme) => theme.palette.action.selected
                }
            }}
            onClick={() => { }}
        >
            <img
                alt={""}
                src={"/assets/stellantis-europe/008.webp"}
                style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "auto",
                    aspectRatio: "1",
                    objectFit: "cover",
                    border: "1px ridge"
                }}
            />

            <Box sx={{ mt: 2, display: 'flex', flexDirection: "column", gap: 1.5 }}>
                {/*                 <Typography variant={"h6"} sx={{ wordBreak: 'break-word' }}>
                    {item.shopType}
                </Typography>

                <Typography variant={"body1"} sx={{ wordBreak: 'break-word' }}>
                    {item.status}
                </Typography> */}

                <CurrencyAdapter
                    value={14.9009}
                    currency={"EUR" as CurrencyTypes}
                    variant={"subtitle1"}
                />
            </Box>

        </Card>
    );
}
